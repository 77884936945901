/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { css, keyframes, styled } from 'styled-components';
import { ReactComponent as ArrowUp } from '@assets/svg/ArrowUp.svg';
import { ReactComponent as Handle } from '@assets/svg/Handler2.svg';
import colorSet from '@styles/colors';
import { typo } from '@components/atoms';
import { MediaQuery } from '@styles/mediaQuery';
import Typo from '../../atoms/Typo';
import EditorInput, { HandleInputProps, IsEditableProps } from '../EditorInput';

interface CardProps {
  children: React.ReactNode;
  title: React.ReactNode | string;
  id?: number;
  isToggle?: boolean;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  borderColor?: string;
  right?: React.ReactNode;
  type?: string;
  isDrag?: boolean;
  isDraggable?: boolean;
  setIsDraggable?: (bool: boolean) => void;
  onChangeTitle?: (str: string) => void;
  titleSubmit?: (str: string, id?: number) => void;
  // data type
  isEditDate?: boolean;
  isTitleEditable?: IsEditableProps;
  setIsTitleEditable?: Dispatch<SetStateAction<IsEditableProps>>;
  editingError?: boolean;
  bottom?: React.ReactNode;
  isEditable?: boolean;
  handleInput?: HandleInputProps;
  isBlur?: boolean;
}

function Card({
  children,
  title,
  id,
  isToggle,
  style,
  contentStyle,
  borderColor,
  right,
  type = 'default',
  isDrag = false,
  isDraggable,
  setIsDraggable,
  onChangeTitle,
  titleSubmit,
  isEditDate,
  isTitleEditable,
  setIsTitleEditable,
  editingError,
  bottom,
  isEditable,
  handleInput,
  isBlur,
}: CardProps) {
  // 아코디언 여닫기 Default true (열림)
  const [isCollapse, setIsCollapse] = useState<boolean>(true);
  // 아코디언 컨텐츠 Wrapper Ref
  const cardContentWrapperRef = useRef<HTMLDivElement>(null);
  // 아코디언 컨텐츠 Ref
  const cardContentRef = useRef<HTMLDivElement>(null);

  //  isToggle Event
  const handleToggleClick = useCallback(() => {
    // if (!cardContentWrapperRef.current || !cardContentRef.current) {
    //   return;
    // }
    // if (cardContentWrapperRef.current.clientHeight > 0) {
    //   cardContentWrapperRef.current.style.height = '0';
    // } else {
    //   cardContentWrapperRef.current.style.height = `${height}px`;
    // }
    setContentHeight(
      cardContentRef.current?.getBoundingClientRect().height || 0,
    );
    if (cardContentWrapperRef?.current) {
      cardContentWrapperRef.current.style.animationName = '';
    }
    setIsCollapse(!isCollapse);
  }, [isCollapse]);

  const [contentHeight, setContentHeight] = useState<number>(0);

  // Header Left Render
  const renderHeaderLeft = () => {
    // default
    if (type === 'edit') {
      return (
        <EditorInput
          onChangeText={onChangeTitle}
          isEditable={isTitleEditable}
          setIsEditable={setIsTitleEditable}
          onSubmit={titleSubmit}
          id={id}
          placeholder={'제목 입력'}
          text={title as string}
          handleInput={handleInput}
        />
      );
    }
    return <CardTitle>{title}</CardTitle>;
  };

  const handelDragOnDown = (mouseType: string) => {
    if (setIsDraggable) {
      if (mouseType === 'down') {
        setIsDraggable(true);
      } else {
        setIsDraggable(false);
      }
    }
  };

  useLayoutEffect(() => {
    if (isEditable) {
      setIsCollapse(true);
    }
  }, [isEditable]);

  useLayoutEffect(() => {
    setContentHeight(
      cardContentRef.current?.getBoundingClientRect().height || 0,
    );

    if (cardContentWrapperRef?.current) {
      cardContentWrapperRef.current.style.animationName = 'none';
    }
  }, []);

  return (
    <CardWrapper
      style={{
        ...style,
      }}
      draggable={isDraggable}
    >
      <CardHeaderWrapper
        $collapse={isCollapse.toString()}
        style={{
          border: borderColor ? '1px solid' : '',
          borderTopColor: borderColor,
          borderLeftColor: borderColor,
          borderRightColor: borderColor,
          borderBottom: `${
            isCollapse ? '0px solid' : '1px solid'
          } ${borderColor}`,
        }}
        $isBlur={isBlur || false}
      >
        <CardHeaderLeft>
          {isDrag && (
            <HandleIcon
              isEditable={!isTitleEditable?.editable}
              onMouseDown={(e) => {
                if (isTitleEditable?.editable) e.preventDefault();
                if (!isTitleEditable?.editable) handelDragOnDown('down');
              }}
            />
          )}
          {renderHeaderLeft()}
        </CardHeaderLeft>
        <CardHeaderRight>
          {right && right}
          {isToggle && (
            <CardHeaderRightToggle onClick={handleToggleClick}>
              <ArrowIcon $collapse={isCollapse.toString()} />
            </CardHeaderRightToggle>
          )}
        </CardHeaderRight>
      </CardHeaderWrapper>
      <CardContentWrapper
        $collapse={isCollapse.toString()}
        $buttonExist={!!bottom}
        style={{
          border:
            borderColor && isCollapse ? `1px solid ${borderColor}` : `none`,
        }}
        height={contentHeight}
        ref={cardContentWrapperRef}
      >
        <CardContent
          id={'test'}
          style={{ ...contentStyle }}
          ref={cardContentRef}
          $collapse={isCollapse}
        >
          {children}
        </CardContent>
      </CardContentWrapper>
      {bottom && <CardBottomWrapper>{bottom}</CardBottomWrapper>}
    </CardWrapper>
  );
}

export default Card;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CardHeaderWrapper = styled.div<{ $collapse: string; $isBlur?: boolean }>`
  display: flex;
  padding: 13px 24px;
  border-radius: 20px 20px
    ${({ $collapse }) => ($collapse === 'true' ? '0px 0px' : '20px 20px')};
  justify-content: space-between;
  align-items: center;
  ${({ $collapse }) =>
    $collapse === 'true' && `border-bottom: 1px solid ${colorSet.gray10};`};
  background-color: #fff;
  ${({ $isBlur }) => $isBlur && `filter: blur(4px);`};
`;

const CardBottomWrapper = styled.div`
  display: flex;
  padding: 13px 24px;
  border-radius: 0px 0px 12px 12px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${colorSet.gray10};
  background-color: #fff;
`;

const CardHeaderLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CardHeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CardHeaderRightToggle = styled.div`
  padding: 8px;
  border: 1px solid #cfd4d9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

interface CardContentWrapperProps {
  $collapse: string;
  $buttonExist?: boolean;
  height?: number;
}

const CardContentWrapper = styled.div<CardContentWrapperProps>`
  // transition: all 0.35s ease;
  width: 100%;

  display: flex;
  overflow: hidden;

  border-radius: ${({ $buttonExist }) =>
    $buttonExist ? '0px' : '0px 0px 20px 20px'};

  ${({ $collapse, height }) =>
    $collapse === 'true'
      ? css`
          height: 100%;
          animation: ${keyframes`
				0% {
					height: 0;
				}
				100% {
					height: ${height}px;
				}
			`} 0.35s;
        `
      : css`
          height: 0;
          animation: ${keyframes`
				0% {
					height: ${height}px;
				}
				100% {
					height: 0;
				}
			`} 0.35s;
        `}
`;

interface CardContentProps {
  $collapse: boolean;
}

const CardContent = styled.div<CardContentProps>`
  transition: all 0.35s ease;
  width: 100%;
  padding: 24px 24px 24px 24px;
  background-color: #fff;
  display: flex;
  height: fit-content;
  align-items: center;

  flex: 100%;
`;

interface ArrowIconProps {
  $collapse: string;
}

const ArrowIcon = styled(ArrowUp)<ArrowIconProps>`
  transition: all ease 0.35s;
  ${({ $collapse }) =>
    $collapse === 'true'
      ? `transform: rotate(0deg);`
      : `transform: rotate(180deg);`}

  ${MediaQuery.FROM_PHONE} {
    width: 16px;
    height: 16px;
  }
`;

interface IconProps {
  isEditable?: boolean;
}

const HandleIcon = styled(Handle)<IconProps>`
  ${({ isEditable }) =>
    isEditable ? `cursor: pointer;` : `cursor: not-allowed`};
  &:hover > path {
    ${({ isEditable }) => (isEditable ? `fill: ${colorSet.gray2};` : ``)}
  }
`;

const CardTitle = styled(Typo)`
  flex: 1;
  ${typo.h3}
  ${MediaQuery.FROM_PHONE} {
    ${typo.h6}
  }
`;
