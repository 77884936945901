// eslint-disable-next-line max-classes-per-file
export class PartnerSettingMarketingReportConfig {
  use: boolean;
}

export class PartnerSettingWindowProgramConfig {
  hideReservationInfo: boolean;

  hideMembershipInfo: boolean;

  useMenuAddReservation: boolean;

  useMenuReservationMessage: boolean;

  useMenuReservationManager: boolean;

  useMenuReservationList: boolean;

  useMenuMembership: boolean;

  useAiSelectManager: boolean;

  useAiSelectAfterNotice: boolean;

  useCallNotification: boolean;

  usePaymentWaitNotification: boolean;

  usePaymentCompleteNotification: boolean;

  useQuickSendMessagePaymentNotification: boolean;

  defaultCheckMessageReview: boolean;

  useSendMessageAfterTreatment: boolean;

  defaultCheckMessageAfterTreatment: boolean;

  defaultCheckMessageAfterTreatmentDetail: boolean;
}

export class PartnerSettingPartnerAppConfig {
  useApp: boolean;

  useQR: boolean;

  usePartnerAndroidApp: boolean;

  usePartnerIosApp: boolean;
}

export enum CrmType {
  VEGAS = 'VEGAS',
  SMART = 'SMART',
  DR_PALETTE = 'DR_PALETTE',
}

export enum PriorityType {
  NORMAL = 'NORMAL',
  TRIVIAL = 'TRIVIAL',
  IMPORTANT = 'IMPORTANT',
}

export enum OccupationType {
  EASY = 'EASY',
  BUSY = 'BUSY',
}

export enum CrmPaymentPointMethod {
  ETC = '기타',
  ETC_ZERO_PAY = '기타-제로페이',
  ETC_LOCAL_CURRENCY = '기타-지역화폐',
}

export enum CrmPaymentCancelPointMethod {
  CANCEL_ETC = '결제취소-기타',
}

export class PartnerSettingHomepageConfig {
  consultingReservationAutoAccept: boolean;

  treatmentReservationAutoAccept: boolean;

  consultationPriorityType: PriorityType;

  treatmentPriorityType: PriorityType;

  managePriorityType: PriorityType;

  defaultServiceTime: number;

  defaultConsultationTime: number;

  defaultForeignerConsultationTime: number;

  defaultAnesthesiaTime: number;

  reservationOccupationType: OccupationType;

  isIncludeVat: boolean;

  defaultAgreeSms: boolean;

  isRequiredConsultationReservationTreatmentCategory: boolean;
}

export class PartnerCrm {
  useVegasCrmTrigger: boolean;

  crmOptionalConfig: {
    vegas: {
      smsDay: number;
      smsNow: number;
      smsBefore: number;
    };
  } | null;

  crmPaymentPointMethod: CrmPaymentPointMethod | null;

  crmPaymentCancelPointMethod: CrmPaymentCancelPointMethod | null;

  isSyncMembershipCustomerLevel: boolean;

  partnerCode?: string | null;

  isSetToken?: boolean;
}

export class PartnerSetting {
  crmType: CrmType | null;

  homepageConfig: PartnerSettingHomepageConfig | null;

  marketingReportConfig: PartnerSettingMarketingReportConfig | null;

  partnerAppConfig: PartnerSettingPartnerAppConfig | null;

  windowProgramConfig: PartnerSettingWindowProgramConfig | null;

  partnerCrm: PartnerCrm | null;
}
