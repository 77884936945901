import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Label, Tooltip, Button } from '@components/atoms';
import dayjs from 'dayjs';
import React from 'react';
import { Billing } from '../../../../@types/billing/models/Billing';

export const billingListCols: ({
  handleSetBillingConfig,
}: {
  handleSetBillingConfig: (data?: Billing) => void;
}) => ColDef[] = ({ handleSetBillingConfig }) => [
  {
    field: 'partnerName',
    minWidth: 160,
    width: 160,
    headerName: '파트너명',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<Billing>) => {
      if (data?.partner) {
        const { partnerName, displayName } = data.partner;
        if (displayName) return displayName;
        return partnerName;
      }
      return '-';
    },
  },
  {
    field: 'productName',
    minWidth: 160,
    width: 160,
    flex: 1,
    headerName: '청구서 상품명',
    sortable: false,
  },
  {
    field: 'state',
    minWidth: 100,
    width: 100,
    headerName: '결제상태',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<Billing>) => {
      return (
        <Label colorType={data?.paidAt ? 'blue' : 'gray'}>
          {data?.paidAt ? '결제완료' : '미결제'}
        </Label>
      );
    },
  },
  {
    field: 'price',
    minWidth: 200,
    width: 200,
    headerName: '금액 (VAT 별도)',
    sortable: false,
    cellStyle: { justifyContent: 'right' },
    cellRenderer: ({ data }: ICellRendererParams<Billing>) => {
      let price = 0;
      data?.billingItems.forEach((item) => {
        price += item.price;
      });
      price = Math.round((price / 11) * 10);
      return `${price.toLocaleString()}원`;
    },
  },
  {
    field: 'billingConfig',
    minWidth: 90,
    width: 90,
    headerName: '담당자',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<Billing>) => {
      if (data?.partner?.billingConfig) {
        const { buyerName, buyerEmail, buyerTel } = data.partner.billingConfig;
        const tooltip = `담당자명: ${buyerName || '-'}\n이메일: ${
          buyerEmail || '-'
        }\n전화번호: ${buyerTel || '-'}`;
        return (
          <Tooltip
            trigger={'hover'}
            title={'담당자 정보'}
            description={tooltip}
          >
            <Label colorType={'primary'}>정보</Label>
          </Tooltip>
        );
      }
      return (
        <Button
          buttonSize={28}
          buttonColor={'gray'}
          onClick={() => {
            handleSetBillingConfig(data);
          }}
        >
          설정
        </Button>
      );
    },
  },
  {
    field: 'sentAt',
    minWidth: 90,
    width: 90,
    headerName: '전송',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<Billing>) => {
      if (data?.sentAt) {
        return (
          <Tooltip
            trigger={'hover'}
            title={'전송일자'}
            description={dayjs(data.sentAt).format('YYYY-MM-DD HH:mm')}
          >
            <Label colorType={'primary'}>완료</Label>
          </Tooltip>
        );
      }
      return <Label colorType={'gray'}>미전송</Label>;
    },
  },
  {
    field: 'dueDate',
    minWidth: 160,
    width: 160,
    headerName: '결제예정일',
    sortable: false,
    cellRenderer: ({ value }: ICellRendererParams<Billing>) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
  },
  {
    field: 'paidAt',
    minWidth: 160,
    width: 160,
    headerName: '결제일',
    sortable: false,
    cellRenderer: ({ value }: ICellRendererParams<Billing>) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
  },
  {
    field: 'createdAt',
    minWidth: 160,
    width: 160,
    headerName: '등록일',
    sortable: false,
    cellRenderer: ({ value }: ICellRendererParams<Billing>) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
  },
];
