/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem, TagLabel } from '@components/molecules';
import { styled } from 'styled-components';
import { Button, Typo } from '@components/atoms';
import { useFormik } from 'formik';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { useToastStore } from '@store/useToastStore';
import { useNavigate, useParams } from 'react-router-dom';
import Skeleton from '@components/molecules/Skeleton';
import { Switch } from 'antd';
import colorSet from '@styles/colors';
import Select from '@components/v2/antd/data-entry/Select';
import Input from '@components/v2/antd/data-entry/Input';
import {
  fetchPartnerSetting,
  upsertPartnerSetting,
} from '../../../../@apis/partner-setting';
import {
  CrmPaymentCancelPointMethod,
  CrmPaymentPointMethod,
  CrmType,
  OccupationType,
  PartnerCrm,
  PartnerSetting,
  PartnerSettingHomepageConfig,
  PartnerSettingMarketingReportConfig,
  PartnerSettingPartnerAppConfig,
  PartnerSettingWindowProgramConfig,
  PriorityType,
} from '../../../../@types/partner/models/PartnerSetting';
import { UpsertPartnerSettingBody } from '../../../../@types/partner/params/UpsertPartnerSettingBody';

const priorityTypeOptions = [
  {
    label: '일반',
    value: PriorityType.NORMAL,
  },
  {
    label: '간단',
    value: PriorityType.TRIVIAL,
  },
  {
    label: '중요',
    value: PriorityType.IMPORTANT,
  },
];

const occupationTypeOptions = [
  {
    label: '여유있게',
    value: OccupationType.EASY,
  },
  {
    label: '분주하게',
    value: OccupationType.BUSY,
  },
];

const crmPaymentPointMethodOptions = [
  {
    label: '기타',
    value: CrmPaymentPointMethod.ETC,
  },
  {
    label: '기타-제로페이',
    value: CrmPaymentPointMethod.ETC_ZERO_PAY,
  },
  {
    label: '기타-지역화폐',
    value: CrmPaymentPointMethod.ETC_LOCAL_CURRENCY,
  },
];

const crmPaymentCancelPointMethodOptions = [
  {
    label: '결제취소-기타',
    value: CrmPaymentCancelPointMethod.CANCEL_ETC,
  },
];

const crmConfigOptions = [
  {
    label: '문자만 발송',
    value: 0,
  },
  {
    label: '카톡만 발송',
    value: 2,
  },
  {
    label: '문자와 카톡 모두 발송',
    value: 3,
  },
  {
    label: '발송 안 함',
    value: 1,
  },
];

const INITIAL_VALUES: PartnerSetting = {
  crmType: null,
  homepageConfig: {
    consultingReservationAutoAccept: false,
    treatmentReservationAutoAccept: false,
    consultationPriorityType: PriorityType.NORMAL,
    treatmentPriorityType: PriorityType.NORMAL,
    managePriorityType: PriorityType.NORMAL,
    defaultServiceTime: 30,
    defaultConsultationTime: 30,
    defaultForeignerConsultationTime: 30,
    defaultAnesthesiaTime: 30,
    reservationOccupationType: OccupationType.EASY,
    isIncludeVat: false,
    defaultAgreeSms: true,
    isRequiredConsultationReservationTreatmentCategory: false,
  },
  partnerAppConfig: {
    useApp: false,
    useQR: false,
    usePartnerAndroidApp: false,
    usePartnerIosApp: false,
  },
  windowProgramConfig: {
    hideReservationInfo: false,
    hideMembershipInfo: false,
    useMenuAddReservation: true,
    useMenuReservationMessage: true,
    useMenuReservationManager: true,
    useMenuReservationList: true,
    useMenuMembership: true,
    useAiSelectManager: true,
    useAiSelectAfterNotice: true,
    useCallNotification: true,
    usePaymentWaitNotification: false,
    usePaymentCompleteNotification: true,
    useQuickSendMessagePaymentNotification: false,
    defaultCheckMessageReview: false,
    useSendMessageAfterTreatment: true,
    defaultCheckMessageAfterTreatment: false,
    defaultCheckMessageAfterTreatmentDetail: false,
  },
  marketingReportConfig: {
    use: false,
  },
  partnerCrm: {
    useVegasCrmTrigger: false,
    crmOptionalConfig: {
      vegas: {
        smsDay: 3,
        smsNow: 3,
        smsBefore: 3,
      },
    },
    crmPaymentPointMethod: null,
    crmPaymentCancelPointMethod: null,
    isSyncMembershipCustomerLevel: false,
  },
};

function PartnerSettingPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToastStore();
  const showAlert = useAlertStore((state) => state.show);

  const [dataFetching, setDataFetching] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const [crmType, setCrmType] = useState<CrmType | null>(null);
  const [partnerCrm, setPartnerCrm] = useState<PartnerCrm | null>(null);

  const formValidationSchema = yup.object({
    homepageConfig: yup.object({
      consultingReservationAutoAccept: yup.boolean().required(),
      treatmentReservationAutoAccept: yup.boolean().required(),
      consultationPriorityType: yup.string().required(),
      treatmentPriorityType: yup.string().required(),
      managePriorityType: yup.string().required(),
      defaultServiceTime: yup.number().required(),
      defaultConsultationTime: yup.number().required(),
      defaultForeignerConsultationTime: yup.number().required(),
      defaultAnesthesiaTime: yup.number().required(),
      reservationOccupationType: yup.string().required(),
      isIncludeVat: yup.boolean().required(),
      defaultAgreeSms: yup.boolean().required(),
    }),
    partnerAppConfig: yup.object({
      useApp: yup.boolean().required(),
      useQR: yup.boolean().required(),
      usePartnerAndroidApp: yup.boolean().required(),
      usePartnerIosApp: yup.boolean().required(),
    }),
    windowProgramConfig: yup.object({
      hideReservationInfo: yup.boolean().required(),
      hideMembershipInfo: yup.boolean().required(),
      useMenuAddReservation: yup.boolean().required(),
      useMenuReservationMessage: yup.boolean().required(),
      useMenuReservationManager: yup.boolean().required(),
      useMenuReservationList: yup.boolean().required(),
      useMenuMembership: yup.boolean().required(),
      useAiSelectManager: yup.boolean().required(),
      useAiSelectAfterNotice: yup.boolean().required(),
      useCallNotification: yup.boolean().required(),
      usePaymentWaitNotification: yup.boolean().required(),
      usePaymentCompleteNotification: yup.boolean().required(),
      useQuickSendMessagePaymentNotification: yup.boolean().required(),
      defaultCheckMessageReview: yup.boolean().required(),
      useSendMessageAfterTreatment: yup.boolean().required(),
      defaultCheckMessageAfterTreatment: yup.boolean().required(),
      defaultCheckMessageAfterTreatmentDetail: yup.boolean().required(),
    }),
    marketingReportConfig: yup.object({
      use: yup.boolean().required(),
    }),
    partnerCrm: yup.object({
      useVegasCrmTrigger: yup.boolean().required(),
      isSyncMembershipCustomerLevel: yup.boolean().required(),
      crmOptionalConfig: yup.object({
        vegas: yup.object({
          smsDay: yup.number().optional(),
          smsNow: yup.number().optional(),
          smsBefore: yup.number().optional(),
        }),
      }),
    }),
  });

  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      if (id) {
        const { partnerCrm, crmType, ...rest } = values;
        const body: UpsertPartnerSettingBody = {
          ...rest,
          partnerCrm: undefined,
        };
        if (crmType === CrmType.VEGAS) {
          body.partnerCrm = partnerCrm;
        }
        await upsert(id, body);
      }
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchSettingData = useCallback(async (id: number | string) => {
    try {
      const { row } = await fetchPartnerSetting(id);
      const {
        homepageConfig,
        partnerAppConfig,
        windowProgramConfig,
        marketingReportConfig,
        partnerCrm,
        crmType,
      } = row;
      setFormInitialValues((prev) => {
        const newValue = {
          ...prev,
        };
        if (crmType) {
          setCrmType(crmType);
          newValue.crmType = crmType;
        }
        if (homepageConfig) {
          const {
            consultingReservationAutoAccept,
            treatmentReservationAutoAccept,
            consultationPriorityType,
            treatmentPriorityType,
            managePriorityType,
            defaultServiceTime,
            defaultConsultationTime,
            defaultForeignerConsultationTime,
            defaultAnesthesiaTime,
            reservationOccupationType,
            isIncludeVat,
            defaultAgreeSms,
            isRequiredConsultationReservationTreatmentCategory,
          } = homepageConfig;
          newValue.homepageConfig = {
            consultingReservationAutoAccept,
            treatmentReservationAutoAccept,
            consultationPriorityType,
            treatmentPriorityType,
            managePriorityType,
            defaultServiceTime,
            defaultConsultationTime,
            defaultForeignerConsultationTime,
            defaultAnesthesiaTime,
            reservationOccupationType,
            isIncludeVat,
            defaultAgreeSms,
            isRequiredConsultationReservationTreatmentCategory,
          };
        }
        if (partnerAppConfig) {
          const { useApp, useQR, usePartnerAndroidApp, usePartnerIosApp } =
            partnerAppConfig;
          newValue.partnerAppConfig = {
            useApp,
            useQR,
            usePartnerAndroidApp,
            usePartnerIosApp,
          };
        }
        if (windowProgramConfig) {
          const {
            hideReservationInfo,
            hideMembershipInfo,
            useMenuAddReservation,
            useMenuReservationMessage,
            useMenuReservationManager,
            useMenuReservationList,
            useMenuMembership,
            useAiSelectManager,
            useAiSelectAfterNotice,
            useCallNotification,
            usePaymentWaitNotification,
            usePaymentCompleteNotification,
            useQuickSendMessagePaymentNotification,
            defaultCheckMessageReview,
            useSendMessageAfterTreatment,
            defaultCheckMessageAfterTreatment,
            defaultCheckMessageAfterTreatmentDetail,
          } = windowProgramConfig;
          newValue.windowProgramConfig = {
            hideReservationInfo,
            hideMembershipInfo,
            useMenuAddReservation,
            useMenuReservationMessage,
            useMenuReservationManager,
            useMenuReservationList,
            useMenuMembership,
            useAiSelectManager,
            useAiSelectAfterNotice,
            useCallNotification,
            usePaymentWaitNotification,
            usePaymentCompleteNotification,
            useQuickSendMessagePaymentNotification,
            defaultCheckMessageReview,
            useSendMessageAfterTreatment,
            defaultCheckMessageAfterTreatment,
            defaultCheckMessageAfterTreatmentDetail,
          };
        }
        if (marketingReportConfig) {
          newValue.marketingReportConfig = {
            use: marketingReportConfig.use,
          };
        }

        if (partnerCrm) {
          setPartnerCrm(partnerCrm);

          if (crmType === CrmType.VEGAS) {
            const {
              useVegasCrmTrigger,
              crmOptionalConfig,
              crmPaymentPointMethod,
              crmPaymentCancelPointMethod,
              isSyncMembershipCustomerLevel,
            } = partnerCrm;
            newValue.partnerCrm = {
              useVegasCrmTrigger,
              crmOptionalConfig: {
                vegas: {
                  smsDay: crmOptionalConfig?.vegas?.smsDay ?? 3,
                  smsNow: crmOptionalConfig?.vegas?.smsNow ?? 3,
                  smsBefore: crmOptionalConfig?.vegas?.smsBefore ?? 3,
                },
              },
              crmPaymentPointMethod,
              crmPaymentCancelPointMethod,
              isSyncMembershipCustomerLevel,
            };
          }
        }
        return newValue;
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setDataFetching(true);
      if (id) {
        await fetchSettingData(id);
      }
      setDataFetching(false);
    })();
  }, [id, fetchSettingData]);

  const upsert = async (
    id: number | string,
    body: UpsertPartnerSettingBody,
  ) => {
    try {
      await upsertPartnerSetting(id, body);
      showToast({
        description: '파트너 설정 정보가 수정되었어요',
        status: 'Primary',
      });
    } catch (e) {
      console.error(e);
    }
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const renderSelectForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof PartnerSetting;
    subKey:
      | keyof PartnerSettingHomepageConfig
      | keyof PartnerSettingMarketingReportConfig
      | keyof PartnerSettingPartnerAppConfig
      | keyof PartnerSettingWindowProgramConfig
      | keyof PartnerCrm;
    placeholder?: string;
    options: { label: string; value: any }[];
    mode?: 'tags' | 'multiple';
  }) => {
    const {
      labelName,
      required = false,
      key,
      subKey,
      placeholder,
      options,
      mode,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    // @ts-ignore
    const value = formik.values[key]?.[subKey] ?? undefined;

    return (
      <FormItem label={labelName} labelMaxWidth={240} optional={!required}>
        <Select
          style={{
            width: '100%',
          }}
          mode={mode}
          showSearch
          allowClear
          value={value}
          options={options}
          onChange={(value) => {
            if (value === null) {
              updateForm(`${key}.${subKey}`, null);
            } else {
              updateForm(`${key}.${subKey}`, value);
            }
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderCrmConfigSelect = (info: {
    labelName: string;
    required?: boolean;
    key: 'vegas' | 'smart' | 'drPalette';
    subKey: 'smsDay' | 'smsNow' | 'smsBefore';
    options: { label: string; value: any }[];
  }) => {
    const { labelName, required = false, key, subKey, options } = info;
    const placeholderText = `${labelName} 입력`;

    const value =
      // @ts-ignore
      formik.values.partnerCrm?.crmOptionalConfig?.[key]?.[subKey] ?? undefined;

    return (
      <FormItem label={labelName} labelMaxWidth={240} optional={!required}>
        <Select
          style={{
            width: '100%',
          }}
          showSearch
          value={value}
          options={options}
          onChange={(value) => {
            if (value === null) {
              updateForm(`partnerCrm.crmOptionalConfig.${key}.${subKey}`, null);
            } else {
              updateForm(
                `partnerCrm.crmOptionalConfig.${key}.${subKey}`,
                value,
              );
            }
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderSwitchForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof PartnerSetting;
    subKey:
      | keyof PartnerSettingHomepageConfig
      | keyof PartnerSettingMarketingReportConfig
      | keyof PartnerSettingPartnerAppConfig
      | keyof PartnerSettingWindowProgramConfig
      | keyof PartnerCrm;
    disabled?: boolean;
  }) => {
    const { labelName, required = false, key, subKey, disabled } = info;
    // @ts-ignore
    const checked = formik.values[key]?.[subKey];
    return (
      <FormItem label={labelName} labelMaxWidth={240} optional={!required}>
        <Switch
          checked={checked}
          onChange={(checked) => {
            updateForm(`${key}.${subKey}`, checked);
          }}
          disabled={disabled}
        />
      </FormItem>
    );
  };

  const renderInputForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof PartnerSetting;
    subKey:
      | keyof PartnerSettingHomepageConfig
      | keyof PartnerSettingMarketingReportConfig
      | keyof PartnerSettingPartnerAppConfig
      | keyof PartnerSettingWindowProgramConfig
      | keyof PartnerCrm;
    placeholder?: string;
    numberMode?: boolean;
    disabled?: boolean;
    infoLabel?: string;
  }) => {
    const {
      labelName,
      required = false,
      disabled,
      key,
      subKey,
      placeholder,
      infoLabel,
      numberMode,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    // @ts-ignore
    const value = formik.values[key]?.[subKey] || '';

    return (
      <FormItem label={labelName} labelMaxWidth={240} optional={!required}>
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <Input
            id={key}
            name={key}
            value={value}
            type={numberMode ? 'number' : 'text'}
            onChange={(e) => {
              updateForm(`${key}.${subKey}`, e.target.value);
            }}
            className="input-container"
            placeholder={placeholderText}
            disabled={disabled}
          />
          {infoLabel && (
            <div
              style={{
                fontSize: 12,
                marginTop: 8,
              }}
            >
              <span>{infoLabel}</span>
            </div>
          )}
        </div>
      </FormItem>
    );
  };

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };

  const handleSaveClick = () => {
    showAlert({
      size: 360,
      title: '파트너 설정 저장',
      message: '파트너 설정을 저장하시겠어요?',
      actions: [
        {
          label: '취소',
        },
        {
          label: '저장',
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const renderFormActions = useCallback(() => {
    let saveButtonDisabled = !formik.isValid;

    if (id) {
      saveButtonDisabled = !formik.isValid || !formik.dirty;
    }

    return (
      <RightButtonWrapper>
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
          저장
        </Button>
      </RightButtonWrapper>
    );
  }, [formik.isValid, formik.dirty, id]);

  const renderCrmTag = useCallback(() => {
    if (crmType) {
      const crmTypeText = () => {
        switch (crmType) {
          case 'VEGAS':
            return '베가스';
          case 'SMART':
            return '스마트닥터';
          case 'DR_PALETTE':
            return '닥터팔레트';
          default:
            return crmType;
        }
      };
      return (
        <div className="flex items-center py-[4px] px-[12px] rounded-full bg-gray-100">
          <Typo typoType="b10m" color="primary4">
            {crmTypeText()} 사용중
          </Typo>
        </div>
      );
    }
    return null;
  }, [crmType]);

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          <Card title={'예약 설정'}>
            <FormLayout>
              {renderSwitchForm({
                labelName: '상담 예약 자동 확정 여부',
                key: 'homepageConfig',
                subKey: 'consultingReservationAutoAccept',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '시술/관리 예약 자동 확정 여부',
                key: 'homepageConfig',
                subKey: 'treatmentReservationAutoAccept',
                required: true,
              })}
              {renderSelectForm({
                labelName: '기본 상담 예약 중요도',
                key: 'homepageConfig',
                subKey: 'consultationPriorityType',
                required: true,
                options: priorityTypeOptions,
              })}
              {renderSelectForm({
                labelName: '기본 시술 예약 중요도',
                key: 'homepageConfig',
                subKey: 'treatmentPriorityType',
                required: true,
                options: priorityTypeOptions,
              })}
              {renderSelectForm({
                labelName: '기본 관리 예약 중요도',
                key: 'homepageConfig',
                subKey: 'managePriorityType',
                required: true,
                options: priorityTypeOptions,
              })}
              {renderInputForm({
                labelName: '타임 슬롯 주기(분)',
                key: 'homepageConfig',
                subKey: 'defaultServiceTime',
                required: true,
              })}
              {renderInputForm({
                labelName: '기본 상담 시간(분)',
                key: 'homepageConfig',
                subKey: 'defaultConsultationTime',
                required: true,
              })}
              {renderInputForm({
                labelName: '기본 외국인 상담 시간(분)',
                key: 'homepageConfig',
                subKey: 'defaultForeignerConsultationTime',
                required: true,
              })}
              {renderInputForm({
                labelName: '마취 시간(분)',
                key: 'homepageConfig',
                subKey: 'defaultAnesthesiaTime',
                required: true,
              })}
              {renderSelectForm({
                labelName: '예약 점유 빈도',
                key: 'homepageConfig',
                subKey: 'reservationOccupationType',
                required: true,
                options: occupationTypeOptions,
              })}
              {renderSwitchForm({
                labelName: 'VAT 포함 여부',
                key: 'homepageConfig',
                subKey: 'isIncludeVat',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '기본 SMS 수신 동의 여부',
                key: 'homepageConfig',
                subKey: 'defaultAgreeSms',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '상담 예약 진료분야 필수 여부',
                key: 'homepageConfig',
                subKey: 'isRequiredConsultationReservationTreatmentCategory',
                required: true,
              })}
            </FormLayout>
          </Card>
          <Card title={'멤버십 앱 설정'}>
            <FormLayout>
              {renderSwitchForm({
                labelName: '멤버십 앱 사용 여부',
                key: 'partnerAppConfig',
                subKey: 'useApp',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '전용 안드로이드 앱 사용 여부',
                key: 'partnerAppConfig',
                subKey: 'usePartnerAndroidApp',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '전용 아이폰 앱 사용 여부',
                key: 'partnerAppConfig',
                subKey: 'usePartnerIosApp',
                required: true,
              })}
              {renderSwitchForm({
                labelName: 'QR 기능 사용 여부',
                key: 'partnerAppConfig',
                subKey: 'useQR',
                required: true,
              })}
            </FormLayout>
          </Card>
          <Card title={'오퍼레이터 설정'}>
            <FormLayout>
              {renderSwitchForm({
                labelName: '예약 정보 숨김 여부',
                key: 'windowProgramConfig',
                subKey: 'hideReservationInfo',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '멤버십 정보 숨김 여부',
                key: 'windowProgramConfig',
                subKey: 'hideMembershipInfo',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '"상담 / 예약" 메뉴 사용 여부',
                key: 'windowProgramConfig',
                subKey: 'useMenuAddReservation',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '"전송 관리" 메뉴 사용 여부',
                key: 'windowProgramConfig',
                subKey: 'useMenuReservationMessage',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '"담당자 관리" 메뉴 사용 여부',
                key: 'windowProgramConfig',
                subKey: 'useMenuReservationManager',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '"예약 신청 목록" 메뉴 사용 여부',
                key: 'windowProgramConfig',
                subKey: 'useMenuReservationList',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '"멤버십 관리" 메뉴 사용 여부',
                key: 'windowProgramConfig',
                subKey: 'useMenuMembership',
                required: true,
              })}
              {renderSwitchForm({
                labelName: 'AI 담당자 자동 선택 사용 여부',
                key: 'windowProgramConfig',
                subKey: 'useAiSelectManager',
                required: true,
              })}
              {renderSwitchForm({
                labelName: 'AI 시술유의사항 자동 선택 사용 여부',
                key: 'windowProgramConfig',
                subKey: 'useAiSelectAfterNotice',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '전화 푸시알림 사용 여부',
                key: 'windowProgramConfig',
                subKey: 'useCallNotification',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '수납대기 푸시알림 사용 여부',
                key: 'windowProgramConfig',
                subKey: 'usePaymentWaitNotification',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '수납완료 푸시알림 사용 여부',
                key: 'windowProgramConfig',
                subKey: 'usePaymentCompleteNotification',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '고객응대 퀵 발송 기능 사용 여부',
                key: 'windowProgramConfig',
                subKey: 'useQuickSendMessagePaymentNotification',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '고객응대 리뷰요청 기본 선택 여부',
                key: 'windowProgramConfig',
                subKey: 'defaultCheckMessageReview',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '고객응대 받은 시술 안내 기능 사용 여부',
                key: 'windowProgramConfig',
                subKey: 'useSendMessageAfterTreatment',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '고객응대 받은 시술 안내 기본 선택 여부',
                key: 'windowProgramConfig',
                subKey: 'defaultCheckMessageAfterTreatment',
                required: true,
              })}
              {renderSwitchForm({
                labelName: '고객응대 받은 시술 안내\n비급여오더 기본 선택 여부',
                key: 'windowProgramConfig',
                subKey: 'defaultCheckMessageAfterTreatmentDetail',
                required: true,
              })}
            </FormLayout>
          </Card>
          <Card title={'마케팅 보고서 설정'}>
            <FormLayout>
              {renderSwitchForm({
                labelName: '마케팅 보고서 사용 여부',
                key: 'marketingReportConfig',
                subKey: 'use',
                required: true,
              })}
            </FormLayout>
          </Card>
          {partnerCrm && (
            <Card
              title={
                <div className="flex w-full items-center gap-4">
                  <Typo typoType="h3">CRM 설정</Typo>
                  {renderCrmTag()}
                </div>
              }
            >
              <FormLayout>
                <FormItem
                  label={'기본 정보'}
                  labelMaxWidth={240}
                  labelStyle={{
                    fontSize: 16,
                    fontWeight: 700,
                    color: '#000',
                  }}
                >
                  <div />
                </FormItem>
                <FormItem label="CRM 파트너 코드" labelMaxWidth={240}>
                  <Typo typoType="b10m">{partnerCrm?.partnerCode || '-'}</Typo>
                </FormItem>
                <FormItem label="CRM 연동 토큰 설정 여부" labelMaxWidth={240}>
                  <Typo typoType="b10m">
                    토큰 설정 {partnerCrm?.isSetToken ? '완료' : '미완료'}
                  </Typo>
                </FormItem>
                {crmType === 'VEGAS' && (
                  <>
                    {renderSwitchForm({
                      labelName: 'CRM 트리거 연동 여부 (베가스)',
                      key: 'partnerCrm',
                      subKey: 'useVegasCrmTrigger',
                      required: true,
                    })}
                    {renderSwitchForm({
                      labelName: 'CRM 멤버십 고객등급 연동 여부 (베가스)',
                      key: 'partnerCrm',
                      subKey: 'isSyncMembershipCustomerLevel',
                      required: true,
                    })}
                    {renderSelectForm({
                      labelName: 'CRM 멤버십 결제 수단 (베가스)',
                      key: 'partnerCrm',
                      subKey: 'crmPaymentPointMethod',
                      options: crmPaymentPointMethodOptions,
                      placeholder: '사용 안 함',
                    })}
                    {renderSelectForm({
                      labelName: 'CRM 멤버십 결제 취소 수단 (베가스)',
                      key: 'partnerCrm',
                      subKey: 'crmPaymentCancelPointMethod',
                      options: crmPaymentCancelPointMethodOptions,
                      placeholder: '사용 안 함',
                    })}

                    <div
                      className="flex mt-5 pt-6"
                      style={{
                        borderTop: `1px solid ${colorSet.gray11}`,
                      }}
                    >
                      <FormItem
                        label={'CRM 전송 연동 설정 (베가스)'}
                        labelMaxWidth={240}
                        labelStyle={{
                          fontSize: 16,
                          fontWeight: 700,
                          color: '#000',
                        }}
                      >
                        <div />
                      </FormItem>
                    </div>
                    {renderCrmConfigSelect({
                      labelName: '당일 발송 옵션',
                      key: 'vegas',
                      subKey: 'smsDay',
                      required: true,
                      options: crmConfigOptions,
                    })}
                    {renderCrmConfigSelect({
                      labelName: '즉시 발송 옵션',
                      key: 'vegas',
                      subKey: 'smsNow',
                      required: true,
                      options: crmConfigOptions,
                    })}
                    {renderCrmConfigSelect({
                      labelName: '전일 발송 옵션',
                      key: 'vegas',
                      subKey: 'smsBefore',
                      required: true,
                      options: crmConfigOptions,
                    })}
                  </>
                )}
              </FormLayout>
            </Card>
          )}
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

export default PartnerSettingPage;

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 8px;
`;
