import React, { useCallback } from 'react';
import PaginationTablePage, {
  TableHeaderKeywordFilterInfo,
} from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import { billingPlanListCols } from '@pages/private/customer/billing-plans/datas';
import { FilterForm } from '@components/organisms/TableFilter/types';
import { fetchBillingPlans } from '../../../../@apis/billing-plans';
import { FetchBillingPlanListQuery } from '../../../../@types/billing-plan/params/FetchBillingPlanListQuery';
import { BillingPlan } from '../../../../@types/billing-plan/models/BillingPlan';
import { IS_ACTIVE_OPTIONS } from '../../../../@types/billing-plan/constants/billing-plan.constants';

function BillingPlanListPage() {
  const navigate = useNavigate();

  const FILTER_INITIAL_VALUES = {
    partnerName: '',
  };

  const filterForms: FilterForm[] = [
    {
      type: 'select',
      field: 'isActive',
      options: IS_ACTIVE_OPTIONS,
      label: '적용중 여부',
      placeholder: '적용중 여부 선택',
    },
  ];

  const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchBillingPlanListQuery> =
    {
      filterKey: 'partnerName',
      searchInputPlaceholder: '파트너명 검색',
    };

  const dataFetcher = useCallback(async (query: FetchBillingPlanListQuery) => {
    const { rows, count } = await fetchBillingPlans({
      ...query,
    });
    return {
      rows,
      count,
    };
  }, []);

  const handleTableRowClick = (data: BillingPlan) => {
    console.log('data', data);
    const { id } = data;
    // navigate(PAGE_PATHS.CUSTOMER_BILLING_PLAN_DETAIL.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        {/*<Link to={PAGE_PATHS.CUSTOMER_BILLING_PLAN_ADD}>*/}
        {/*  <Button>청구 계획 등록</Button>*/}
        {/*</Link>*/}
      </RightButtonWrapper>
    );
  };

  const handleUseRowCheck = (data?: BillingPlan) => {};

  const handleSetBillingConfig = (data?: BillingPlan) => {};

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={billingPlanListCols({
        handleUseRowCheck,
        handleSetBillingConfig,
      })}
      filterInitialValues={FILTER_INITIAL_VALUES}
      onRowClick={handleTableRowClick}
      keywordFilterInfo={keywordFilterInfo}
      filterForms={filterForms}
    />
  );
}

export default BillingPlanListPage;
