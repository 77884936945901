import { AppError, request, Row } from '@networks/index';
import { PartnerSetting } from '../@types/partner/models/PartnerSetting';
import { UpsertPartnerSettingBody } from '../@types/partner/params/UpsertPartnerSettingBody';

const API_ROOT = 'admin/v1/partners';

export const fetchPartnerSetting = async (id: number | string) => {
  const url = `${API_ROOT}/${id}/settings`;
  try {
    const { data } = await request<Row<PartnerSetting>>({
      method: 'get',
      url,
    });

    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const upsertPartnerSetting = async (
  id: number | string,
  body: UpsertPartnerSettingBody,
) => {
  const url = `${API_ROOT}/${id}/settings`;
  try {
    await request({
      method: 'put',
      url,
      requestBody: body,
    });
  } catch (e) {
    throw new AppError(e);
  }
};
