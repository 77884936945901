import React, {
  CSSProperties,
  DetailedHTMLProps,
  LabelHTMLAttributes,
  ReactNode,
} from 'react';
import { styled } from 'styled-components';
import { Typo, typo } from '@components/atoms';
import colorSet from '@styles/colors';

interface FormItemProps
  extends DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {
  label: string;
  optionalText?: string;
  maxSize?: number;
  optional?: boolean;
  children?: ReactNode;
  labelStyle?: CSSProperties;
  viewStyle?: CSSProperties;
  labelMaxWidth?: number;
}

function FormItem({
  label,
  optionalText,
  maxSize = 520,
  optional,
  children,
  labelStyle,
  viewStyle,
  style,
  labelMaxWidth = 120,
  ...rest
}: FormItemProps) {
  return (
    <FormItemView style={style}>
      <FormLabelView style={viewStyle} $labelMaxWidth={labelMaxWidth}>
        <FormLabelText style={labelStyle} {...rest}>
          {label}
        </FormLabelText>
        {optional && (
          <FormOptionalLabelText>
            {optionalText || '(선택)'}
          </FormOptionalLabelText>
        )}
      </FormLabelView>
      <FormContentLayout $size={maxSize}>{children}</FormContentLayout>
    </FormItemView>
  );
}

export default FormItem;

const FormItemView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const FormLabelView = styled.div<{ $labelMaxWidth: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${({ $labelMaxWidth }) => $labelMaxWidth}px;
  min-width: 120px;
  height: 40px;
  margin-right: 8px;
  flex-wrap: wrap;
`;

const FormLabelText = styled.label`
  ${typo.b11}
  color: ${colorSet.gray6};
  white-space: pre-wrap;
`;

const FormOptionalLabelText = styled(Typo)`
  margin-left: 2px;
  ${typo.b13}
  color: ${colorSet.gray8};
`;

const FormContentLayout = styled.div<{ $size?: number }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  max-width: ${({ $size }) => $size}px;
`;
