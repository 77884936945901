import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Label, Tooltip, Button } from '@components/atoms';
import dayjs from 'dayjs';
import React from 'react';
import { Partner } from '../../../../@types/partner/models/Partner';

const dayToLabel: any = {
  '0': '일',
  '1': '월',
  '2': '화',
  '3': '수',
  '4': '목',
  '5': '금',
  '6': '토',
};

export const partnerListCols: ({
  handleSettingRowClick,
}: {
  handleSettingRowClick: (data?: Partner) => void;
}) => ColDef[] = ({ handleSettingRowClick }) => [
  {
    field: 'partnerName',
    minWidth: 153,
    width: 153,
    flex: 1,
    headerName: '파트너명',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<Partner>) => {
      if (data) {
        const { partnerName, displayName } = data;
        if (displayName) return displayName;
        return partnerName;
      }
      return '-';
    },
  },
  {
    field: 'partnerCode',
    minWidth: 170,
    width: 170,
    headerName: '파트너코드',
    sortable: false,
  },
  {
    field: 'partnerCeoName',
    minWidth: 170,
    width: 170,
    headerName: '대표자명',
    sortable: false,
  },
  {
    field: 'agency',
    minWidth: 100,
    width: 100,
    headerName: '대행사',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<Partner>) => {
      if (data?.agency) {
        return data.agency.agencyName;
      }
      return <Label colorType={'red'}>설정 필요</Label>;
    },
  },
  {
    field: 'weeklyPartnerSchedules',
    minWidth: 100,
    width: 100,
    headerName: '영업시간',
    sortable: false,
    // eslint-disable-next-line react/no-unstable-nested-components
    cellRenderer: ({ data }: ICellRendererParams<Partner>) => {
      if (data?.weeklyPartnerSchedules?.length) {
        let isSetting = true;
        let tooltip = '';
        let errorTooltip = '';
        for (let i = 0; i < data.weeklyPartnerSchedules.length; i += 1) {
          const schedule = data.weeklyPartnerSchedules[i];
          tooltip += `${dayToLabel[schedule.day]}: `;
          if (!schedule.isNotWork) {
            if (!schedule.workStartedAt || !schedule.workEndedAt) {
              isSetting = false;
              errorTooltip = '영업시간 설정 필요';
              break;
            }
            tooltip += `영업시간 (${schedule.workStartedAt} ~ ${schedule.workEndedAt}) | `;
            if (!schedule.isNotLunch) {
              if (!schedule.lunchStartedAt || !schedule.lunchEndedAt) {
                isSetting = false;
                errorTooltip = '점심시간 설정 필요';
                break;
              }
              tooltip += `점심시간 (${schedule.lunchStartedAt} ~ ${schedule.lunchEndedAt})`;
            } else {
              tooltip += `점심시간 없음`;
            }
          } else {
            tooltip += `휴무`;
          }
          tooltip += `\n`;
        }
        if (isSetting) {
          return (
            <Tooltip
              trigger={'hover'}
              title={'설정 완료'}
              description={tooltip}
            >
              <Label colorType={'blue'}>설정 완료</Label>
            </Tooltip>
          );
        }
        return (
          <Tooltip
            trigger={'hover'}
            title={'설정 필요'}
            description={errorTooltip}
          >
            <Label colorType={'red'}>설정 필요</Label>
          </Tooltip>
        );
      }
      return <Label colorType={'red'}>설정 필요</Label>;
    },
  },
  {
    field: 'marketingReportConfig',
    minWidth: 100,
    width: 100,
    headerName: '보고서',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<Partner>) => {
      if (data?.marketingReportConfig?.use) {
        return <Label colorType={'blue'}>사용</Label>;
      }
      return <Label colorType={'red'}>미사용</Label>;
    },
  },
  {
    field: 'setting',
    minWidth: 80,
    width: 80,
    headerName: '설정',
    sortable: false,
    resizable: false,
    onCellClicked: (e) => {
      e.event?.stopPropagation();
      e.event?.preventDefault();
    },
    cellRenderer: ({ data }: ICellRendererParams<Partner>) => {
      return (
        <Button
          buttonSize={28}
          buttonColor={'gray'}
          onClick={() => {
            handleSettingRowClick(data);
          }}
        >
          설정
        </Button>
      );
    },
  },
  {
    field: 'createdAt',
    minWidth: 153,
    width: 153,
    headerName: '등록일',
    cellRenderer: ({ value }: ICellRendererParams<Partner>) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
    sortable: false,
  },
  {
    field: 'updatedAt',
    minWidth: 153,
    width: 153,
    headerName: '마지막 수정일',
    cellRenderer: ({ value }: ICellRendererParams) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
    sortable: false,
  },
];
