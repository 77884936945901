import React, { useCallback } from 'react';
import PaginationTablePage, {
  TableHeaderKeywordFilterInfo,
} from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import { billingListCols } from '@pages/private/customer/billings/datas';
import { FilterForm } from '@components/organisms/TableFilter/types';
import { fetchBillings } from '../../../../@apis/billings';
import { FetchBillingListQuery } from '../../../../@types/billing/params/FetchBillingListQuery';
import { Billing } from '../../../../@types/billing/models/Billing';
import { IS_PAID_OPTIONS } from '../../../../@types/billing/constants/billing.contstants';

function BillingListPage() {
  const navigate = useNavigate();

  const FILTER_INITIAL_VALUES = {
    partnerName: '',
  };

  const filterForms: FilterForm[] = [
    {
      type: 'select',
      field: 'isPaid',
      options: IS_PAID_OPTIONS,
      label: '결제 상태',
      placeholder: '결제 상태 선택',
    },
  ];

  const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchBillingListQuery> =
    {
      filterKey: 'partnerName',
      searchInputPlaceholder: '파트너명 검색',
    };

  const dataFetcher = useCallback(async (query: FetchBillingListQuery) => {
    const { rows, count } = await fetchBillings({
      ...query,
    });
    return {
      rows,
      count,
    };
  }, []);

  const handleTableRowClick = (data: Billing) => {
    console.log('data', data);
    const { id } = data;
    // navigate(PAGE_PATHS.CUSTOMER_BILLING_DETAIL.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        {/*<Link to={PAGE_PATHS.CUSTOMER_BILLING_ADD}>*/}
        {/*  <Button>청구 계획 등록</Button>*/}
        {/*</Link>*/}
      </RightButtonWrapper>
    );
  };

  const handleSetBillingConfig = (data?: Billing) => {};

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={billingListCols({
        handleSetBillingConfig,
      })}
      filterInitialValues={FILTER_INITIAL_VALUES}
      onRowClick={handleTableRowClick}
      keywordFilterInfo={keywordFilterInfo}
      filterForms={filterForms}
    />
  );
}

export default BillingListPage;
