import React, { useCallback } from 'react';
import PaginationTablePage, {
  TableHeaderKeywordFilterInfo,
} from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import { partnerListCols } from '@pages/private/customer/partners/datas';
import { fetchPartners } from '../../../../@apis/partners';
import { FetchPartnerListQuery } from '../../../../@types/partner/params/FetchPartnerListQuery';
import { Partner } from '../../../../@types/partner/models/Partner';

function PartnerListPage() {
  const navigate = useNavigate();

  const FILTER_INITIAL_VALUES = {};

  const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchPartnerListQuery> =
    {
      filterKey: 'partnerName',
      searchInputPlaceholder: '파트너명 검색',
    };

  const dataFetcher = useCallback(async (query: FetchPartnerListQuery) => {
    const { rows, count } = await fetchPartners({
      ...query,
      relations: ['weeklyPartnerSchedules', 'agency', 'marketingReportConfig'],
    });
    return {
      rows,
      count,
    };
  }, []);

  const handleTableRowClick = (data: Partner) => {
    console.log('data', data);
    const { id } = data;
    navigate(PAGE_PATHS.CUSTOMER_PARTNER_DETAIL.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.CUSTOMER_PARTNER_ADD}>
          <Button>파트너 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  const handleSettingRowClick = (data?: Partner) => {
    console.log('id', data?.id);
    if (data?.id) {
      navigate(
        PAGE_PATHS.CUSTOMER_PARTNER_SETTING.replace(':id', `${data.id}`),
      );
    }
  };

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={partnerListCols({
        handleSettingRowClick,
      })}
      filterInitialValues={FILTER_INITIAL_VALUES}
      onRowClick={handleTableRowClick}
      keywordFilterInfo={keywordFilterInfo}
    />
  );
}

export default PartnerListPage;
