export const IS_PAID_OPTIONS = [
  {
    label: '결제완료',
    value: true,
  },
  {
    label: '미결제',
    value: false,
  },
];
