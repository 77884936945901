// eslint-disable-next-line import/prefer-default-export
export const PAGE_PATHS = {
  HOME: '/',
  LOGIN: '/login',
  INITIAL_LOGIN: '/initial-login',
  TEST: '/test',

  /**
   * 고객 관리
   */
  CUSTOMER: '/customer',
  CUSTOMER_PARTNERS: '/customer/partners',
  CUSTOMER_PARTNER_ADD: '/customer/partners/add',
  CUSTOMER_PARTNER_DETAIL: '/customer/partners/detail/:id',
  CUSTOMER_PARTNER_SETTING: '/customer/partners/setting/:id',
  CUSTOMER_PARTNER_GROUPS: '/customer/partner-groups',
  CUSTOMER_PARTNER_GROUP_ADD: '/customer/partner-groups/add',
  CUSTOMER_PARTNER_GROUP_DETAIL: '/customer/partner-groups/detail/:id',
  CUSTOMER_PARTNER_ACCOUNTS: '/customer/partner-accounts',
  CUSTOMER_PARTNER_ACCOUNT_ADD: '/customer/partner-accounts/add',
  CUSTOMER_PARTNER_ACCOUNT_DETAIL: '/customer/partner-accounts/detail/:id',
  CUSTOMER_AI_CONSULTATION_RESPONSES: '/customer/ai-consultation-responses',
  CUSTOMER_AI_CONSULTATION_RESPONSE_ADD:
    '/customer/ai-consultation-responses/add',
  CUSTOMER_AI_CONSULTATION_RESPONSE_DETAIL:
    '/customer/ai-consultation-responses/detail/:id',
  CUSTOMER_CRM_RESERVATIONS: '/customer/crm-reservations',
  CUSTOMER_BILLING_PLANS: '/customer/billing-plans',
  CUSTOMER_BILLING_PLAN_ADD: '/customer/billing-plans/add',
  CUSTOMER_BILLING_PLAN_DETAIL: '/customer/billing-plans/detail/:id',
  CUSTOMER_BILLINGS: '/customer/billings',
  CUSTOMER_BILLING_ADD: '/customer/billings/add',
  CUSTOMER_BILLING_DETAIL: '/customer/billings/detail/:id',

  /**
   * AI 디자이너
   */
  AI_DESIGNER: '/ai-designs',
  AI_DESIGNER_TEMPLATES_V2: '/ai-designs/templates',
  AI_DESIGNER_TEMPLATE_ADD_V2: '/ai-designs/templates/add',
  AI_DESIGNER_TEMPLATE_DETAIL_V2: '/ai-designs/templates/detail/:id',
  AI_DESIGNER_ASSETS: '/ai-designs/assets',
  AI_DESIGNER_ASSET_ADD: '/ai-designs/assets/add',
  AI_DESIGNER_ASSET_DETAIL: '/ai-designs/assets/detail/:id',
  AI_MODEL_IMAGES: '/ai-designs/model-images',
  AI_MODEL_BASE_IMAGES: '/ai-designs/model-base-images',

  /**
   * AI 데이터
   */
  // 시술정보
  AI_DATA: '/ai-data',
  AI_DATA_GLOBAL_CATALOGS: '/ai-data/global-catalogs',
  AI_DATA_GLOBAL_CATALOG_ADD: '/ai-data/global-catalogs/add',
  AI_DATA_GLOBAL_CATALOG_DETAIL: '/ai-data/global-catalogs/detail/:id',

  // 시술장비
  AI_DATA_GLOBAL_THROUGHPUT_ITEMS: '/ai-data/global-throughput-items',
  AI_DATA_GLOBAL_THROUGHPUT_ITEM_ADD: '/ai-data/global-throughput-items/add',
  AI_DATA_GLOBAL_THROUGHPUT_ITEM_DETAIL:
    '/ai-data/global-throughput-items/detail/:id',

  // 블로그 라벨링
  AI_DATA_BLOG_LABELS: '/ai-data/blog-labels',
  AI_DATA_BLOG_LABELS_ADD: '/ai-data/blog-labels/add',
  AI_DATA_BLOG_LABELS_DETAIL: '/ai-data/blog-labels/detail/:id',

  // 블로그 레퍼런스
  AI_DATA_BLOG_REFERENCES: '/ai-data/blog-references',
  AI_DATA_BLOG_REFERENCE_ADD: '/ai-data/blog-references/add',
  AI_DATA_BLOG_REFERENCE_DETAIL: '/ai-data/blog-references/detail/:id',

  // 글로벌 프롬프트
  AI_DATA_GLOBAL_PROMPTS: '/ai-data/global-prompts',
  AI_DATA_GLOBAL_PROMPT_ADD: '/ai-data/global-prompts/add',
  AI_DATA_GLOBAL_PROMPT_DETAIL: '/ai-data/global-prompts/detail/:id',

  /**
   * 블로그 구독 관리
   */
  // 블로그 구독 정보
  BLOG: '/blogs',
  BLOG_SUBSCRIPTIONS: '/blogs/subscriptions',
  BLOG_SUBSCRIPTIONS_DETAIL: '/blogs/subscriptions/detail/:id',

  /**
   * 퍼포먼스 마케팅
   */
  // 블로그 구독 정보
  ADS: '/ads',
  ADS_PARTNERS: '/ads/partners',
  ADS_CAMPAIGNS: '/ads/partners/:partnerid',
  ADS_CAMPAIGN_DETAIL: '/ads/partners/:partnerid/campaigns/:campaignid',
  ADS_CAMPAIGN_ADD: '/ads/partners/:partnerid/campaigns/add',

  TEST_PAGE: '/test/test',

  /**
   * 메뉴/권한 설정
   */
  SETTINGS: '/settings',
  SETTINGS_MENUS: '/settings/menus',
  SETTINGS_MENU_ADD: '/settings/menus/add',
  SETTINGS_MENUS_DETAIL: '/settings/menus/detail/:id',
  SETTINGS_AUTHORITY_GROUPS: '/settings/authority-groups',
  SETTINGS_AUTHORITY_GROUP_ADD: '/settings/authority-groups/add',
  SETTINGS_AUTHORITY_GROUPS_DETAIL: '/settings/authority-groups/detail/:id',

  /**
   * 리뷰 설정
   */
  REVIEW_CONFIG: '/review-config',
  REVIEW_CONFIG_TEMPLATES: '/review-config/templates',
  REVIEW_CONFIG_TEMPLATE_ADD: '/review-config/templates/add',
  REVIEW_CONFIG_TEMPLATES_DETAIL: '/review-config/templates/detail/:id',
  REVIEW_CONFIG_QUESTIONS: '/review-config/questions',
  REVIEW_CONFIG_QUESTION_ADD: '/review-config/questions/add',
  REVIEW_CONFIG_QUESTIONS_DETAIL: '/review-config/questions/detail/:id',
};

export const INITIAL_PRIVATE_ROUTE = PAGE_PATHS.CUSTOMER_PARTNERS;
