import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Label, Tooltip, Button } from '@components/atoms';
import dayjs from 'dayjs';
import React from 'react';
import { BillingPlan } from '../../../../@types/billing-plan/models/BillingPlan';

export const billingPlanListCols: ({
  handleUseRowCheck,
  handleSetBillingConfig,
}: {
  handleUseRowCheck: (data?: BillingPlan) => void;
  handleSetBillingConfig: (data?: BillingPlan) => void;
}) => ColDef[] = ({ handleUseRowCheck, handleSetBillingConfig }) => [
  {
    field: 'partnerName',
    minWidth: 160,
    width: 160,
    headerName: '파트너명',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<BillingPlan>) => {
      if (data?.partner) {
        const { partnerName, displayName } = data.partner;
        if (displayName) return displayName;
        return partnerName;
      }
      return '-';
    },
  },
  {
    field: 'productName',
    minWidth: 160,
    width: 160,
    flex: 1,
    headerName: '청구서 상품명',
    sortable: false,
  },
  {
    field: 'state',
    minWidth: 90,
    width: 90,
    headerName: '상태',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<BillingPlan>) => {
      return (
        <Label colorType={data?.isActive ? 'blue' : 'gray'}>
          {data?.isActive ? '적용중' : '미적용'}
        </Label>
      );
    },
  },
  {
    field: 'price',
    minWidth: 200,
    width: 200,
    headerName: '금액 (VAT 별도)',
    sortable: false,
    cellStyle: { justifyContent: 'right' },
    cellRenderer: ({ data }: ICellRendererParams<BillingPlan>) => {
      let price = 0;
      data?.billingPlanItems.forEach((item) => {
        price += item.price;
      });
      price = Math.round((price / 11) * 10);
      return `${price.toLocaleString()}원`;
    },
  },
  {
    field: 'billingDate',
    minWidth: 90,
    width: 90,
    headerName: '결제일',
    sortable: false,
    cellRenderer: ({ value }: ICellRendererParams<BillingPlan>) => {
      return `${value}일`;
    },
  },
  {
    field: 'billingConfig',
    minWidth: 90,
    width: 90,
    headerName: '담당자',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<BillingPlan>) => {
      if (data?.partner?.billingConfig) {
        const { buyerName, buyerEmail, buyerTel } = data.partner.billingConfig;
        const tooltip = `담당자명: ${buyerName || '-'}\n이메일: ${
          buyerEmail || '-'
        }\n전화번호: ${buyerTel || '-'}`;
        return (
          <Tooltip
            trigger={'hover'}
            title={'담당자 정보'}
            description={tooltip}
          >
            <Label colorType={'primary'}>정보</Label>
          </Tooltip>
        );
      }
      return (
        <Button
          buttonSize={28}
          buttonColor={'gray'}
          onClick={() => {
            handleSetBillingConfig(data);
          }}
        >
          설정
        </Button>
      );
    },
  },
  {
    field: 'startDate',
    minWidth: 160,
    width: 160,
    headerName: '적용일',
    sortable: false,
    cellRenderer: ({ value }: ICellRendererParams<BillingPlan>) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
  },
  {
    field: 'createdAt',
    minWidth: 160,
    width: 160,
    headerName: '등록일',
    sortable: false,
    cellRenderer: ({ value }: ICellRendererParams<BillingPlan>) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
  },
];
